<template>
  <div class="access">
    <div style="padding: 3rem 1.5rem 3rem">
      <div class="container is-widescreen" style="padding: 3rem 0rem 3rem">
        <div
          class="column is-full"
          style="
            font-weight: 700;
            font-size: 1.75em;
            text-align: center;
            color: #2e3192;
            padding: 0rem 0rem 3rem;
          "
        >
          交通アクセス
        </div>
        <div class="columns">
          <!-- <div class="column is-two-third"> -->
          <div class="column is-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d800.184208381268!2d138.30464048817146!3d36.65678119874251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601d8e968b05a5e7%3A0xa2df02ed261c3476!2z5YyX6YeO5bu66Kit77yI5qCq77yJ6aCI5Z2C5Za25qWt5omA!5e0!3m2!1sja!2sjp!4v1626473332645!5m2!1sja!2sjp"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <div style="padding: 1rem 0rem 1rem">
              <a
                class="button is-info is-outlined"
                target="_blank"
                href="https://goo.gl/maps/9qNgGZkck8cxfd2P9"
              >
                <span>Googleマップで開く</span>
                <span class="icon is-small">
                  <i class="mdi mdi-24px mdi-open-in-new"></i>
                </span>
              </a>
            </div>
            <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-train"></i>
                </span>
                <span>長野電鉄須坂駅下車 徒歩1分</span>
              </span>
            </div>
            <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-parking"></i>
                </span>
                <span>駐車場なし</span>
              </span>
            </div>
          </div>
          <!-- <div class="column is-one-third"> -->
          <!-- <div style="font-size: 1.5rem; font-weight: 700;">株式会社if</div> -->
          <!-- <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-map-marker"></i>
                </span>
                <span>〒382-0076 長野県須坂市1298-13</span>
              </span>
            </div> -->
          <!-- <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-phone"></i>
                </span>
                <span>026-285-9795</span>
              </span>
            </div> -->
          <!-- <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-fax"></i>
                </span>
                <span>026-285-9794</span>
              </span>
            </div> -->
          <!-- <div style="padding: 1rem 0rem 0rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-email"></i>
                </span>
                <span>info@rehome-if.co.jp</span>
              </span>
            </div> -->
          <!-- <div style="padding: 1rem 0rem 1rem">
              <span class="icon-text">
                <span class="icon">
                  <i class="mdi mdi-24px mdi-web"></i>
                </span>
                <span>https://rehome-if.co.jp</span>
              </span>
            </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class News extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
  }
}
</script>
<style lang="scss">
.access {
  padding-top: 3.25rem;
}
</style>
